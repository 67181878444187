import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';


// other libraries
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

//material-ui
// import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


//API
import {postLead} from '../../utils/Api'


//GOOGLE ANALYTICS
import ReactGA from "react-ga4";

ReactGA.initialize("G-8BNDD3LX7W");




const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const [open, setOpen] = React.useState(false);

  const {
    // register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nome: "",
      email:"",
      celular:"",
      aceite:""
    }
  });


  const onSubmit = async data => {

    const values = {
      nome: data.nome,
      email:data.email.replace(/\s/g, ""),
      celular: data.celular || "",
      fonte: "HeroLand001",
      lancamento: "0",
      interesse: "consultoria financeira",
    }

    console.log(values)

    const res = await postLead(values);
    
    if (String(res) === '201') {


      ReactGA.event({
        category: "Lead",
        action: "LeadSubmit",
        label: "Hero", // optional
        value: 1, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });

      alert('Muito obrigado! Entraremos em contato em breve.');

      window.location.href='/'; 
    
    } else {

      ReactGA.event({
        category: "Lead",
        action: "LeadFail",
        label: "Hero", // optional
        value: 0, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });

      alert('Erro ao registrar seus dados!');
    }

  };

  const onErrors = errors => {
    // console.error(errors)
  };


  const handleClickOpen = () => {
    setOpen(true);

    ReactGA.event({
      category: "Lead",
      action: "LeadOpen",
      label: "Hero", // optional
      value: 5, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      // transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const handleClose = () => {
    setOpen(false);
  };




  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   if (validate()) {
  //     const res = await postLead(values);

  //       if (String(res) === '201') {

  //         // ReactGA.event({
  //         //   category: 'Links',
  //         //   action: 'GitHub',
  //         //   label: 'GitHub button clicked!',
  //         //   value: 1
  //         // });

  //         ReactGA.event({
  //           category: "Lead",
  //           action: "LeadSubmitProgram",
  //           label: "Hero", // optional
  //           value: 1, // optional, must be a number
  //           // nonInteraction: true, // optional, true/false
  //           // transport: "xhr", // optional, beacon/xhr/image
  //         });






  //         alert('Muito obrigado! Entrarei em contato em breve.');

  //         window.location.href='/'; 
  //       } else {
  //         alert('Erro ao registrar seus dados!');
  //         }
  //   }
  // }

  const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

  const handleEmailValidation = email => {
    // console.log("ValidateEmail was called with", email);

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      // console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };






  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              O passo a passo para sua empresa atingir a <span className="text-color-primary"> alta lucratividade</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Já pensou em ter um especialista ao seu lado para te ajudar a transformar a sua empresa em um negócio altamente lucrativo?
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile onClick={handleClickOpen}>
                    Tenho interesse
                  </Button>
                  {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              {/* <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} /> */}
            </a>
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
        </div>
      </div>




        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={handleSubmit(onSubmit,  onErrors)}>

            <DialogTitle> 
            <b style={{fontSize: "0.8rem", color:'#6d6d6d', fontWeight: 500,  }} >
            QUERO SABER MAIS 
            </b>

            </DialogTitle>
              <DialogContent>
                  <br/>
                <Controller
                  name={"nome"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField fullWidth autoFocus onChange={onChange} value={value} label={"Nome"} />
                  )}
                />
                {errors.nome && errors.nome.type === "required" && 
                  <span style={{fontSize: "0.8rem", color:'red', fontWeight: 500,  }}>
                    Informe seu nome. 
                  </span>
                }

                <br></br>
                <br></br>

                <Controller
                  id={"email"}
                  name={"email"}
                  control={control}
                  rules={{ required: true, validate: handleEmailValidation}}

                  render={({ field: { onChange, onBlur,value } }) => (
                    <TextField 
                      fullWidth 
                      onBlur={onBlur}
                      onChange={onChange} 
                      value={value} 
                      label={"Email"} 
                      
                    />


                  )}

                />
                {errors.email && errors.email.type === "required" && 
                  <span style={{fontSize: "0.8rem", color:'red', fontWeight: 500,  }}>
                    Informe seu email. 
                  </span>
                }
                {errors.email && 
                  <span style={{fontSize: "0.8rem", color:'red', fontWeight: 500,  }}>
                    Não válido.
                  </span>
                }


                <br></br>
                <br></br>

                <Controller
                  name="celular"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                      <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                          {
                              inputProps => (
                                  <TextField
                                      // error={!!errors.phone?.message}
                                      label="Celular (opcional)"
                                      variant="outlined"
                                      type="text"
                                      fullWidth
                                      {...inputProps}
                                  />
                              )
                          }
                      </InputMask>
                  )}
                />

                <br></br>
                <br></br>

            {/* <FormHelperText style={{color: 'red'}}>{error}</FormHelperText> */}
                <Controller
                  id={"aceite"}
                  name={"aceite"}
                  control={control}
                  rules={{ required: true}}
                  render={({ field: { onChange, value } }) => (
                    // <TextField onChange={onChange} value={value} label={"aceite"} />
                    // <Checkbox onChange={onChange} value={value} label="De acordo" />
                    <FormControlLabel control={
                    <Checkbox onChange={onChange} value={value} />
                  } 
                  label={<b style={{fontSize: "0.8rem", color:'#6d6d6d', fontWeight: 400,  }} >Estou de acordo</b>} 
                  />
                    )}
                />
                <br/>
                {errors.aceite && errors.aceite.type === "required" && 
                  <span style={{fontSize: "0.8rem", color:'red', fontWeight: 500,  }}>
                    Necessário aceitar.
                  </span>
                }



              <div className="disclaimerandnotes" align='justify'> <b  style={{fontSize: "0.7rem", color:'#6d6d6d', fontWeight: 500,  }} >
                De acordo com as Leis 12.965/2014 e 13.709/2018, que regulam o uso da Internet e o tratamento de dados pessoais no Brasil, autorizo o Artificial Mentor a enviar notificações por e-mail ou outros meios e concordo com sua Política de Privacidade.
              <br></br>
              Suas informações estão seguras.
              </b>
              </div>



              
   
              </DialogContent>


          <DialogActions>

          <ButtonGroup>
            <Button onClick={handleClose} wideMobile ><b>Cancelar</b></Button>
            <Button type="submit" color="primary" wideMobile>
            Enviar
            </Button>
          </ButtonGroup>
          </DialogActions>

        </form>

        </Dialog>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;