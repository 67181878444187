import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Ouça nossos clientes',
    // paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — “Depois que comecei a mentoria a minha escola expandiu bastante, consegui em pouquíssimo tempo quase triplicar o número de alunos. Ele me deu suporte, segurança e ideias brilhantes, foi essencial e fez toda diferença pra a Sol Maior Musicalização.”
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Thaís</span>
                  <span className="text-color-low"> <br/> </span>
                  <span className="testimonial-item-link">
                    <a href="https://www.instagram.com/sol_maiormusicalizacao/">Sol Maior Musicalização / Educação</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  — Vai muito além de uma consultoria, é um grande aprendizado sobre como superar todos os desafios encontrados no dia a dia de uma empresa e de como enfrenta-los e supera-los com muito planejamento e sucesso.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Luciana e Fernanda </span>
                  <span className="text-color-low"> <br/></span>
                  <span className="testimonial-item-link">
                    <a href="https://www.instagram.com/miss.country/">Miss Country / Moda </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">




                  <span style={{fontSize: "0.7rem"}}>
                  — ...todo software e treinamento foi desenvolvido pelo Artificial Mentor, que além de desenvolver todo o programa para as nossas necessidades, também preparou nossa equipe para mais essa ferramenta que veio para agregar na gestão dos nossos processos internos e projetos.
                  Agradecemos ao Vinicius pelo excelente trabalho e parabenizamos toda nossa equipe pelo empenho e dedicação!
                  </span>



                    

                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Mariana</span>
                  <span className="text-color-low"> <br/> </span>
                  <span className="testimonial-item-link">
                  <a href="https://www.instagram.com/hmf.projetos/">HMF Projetos / Engenharia e Construção </a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;