import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

// import Input from '../elements/Input';


// other libraries
import { useForm, Controller } from "react-hook-form";


// MUI
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Container, Grid } from '@mui/material';





//API
import {postLead} from '../../utils/Api'

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const {
    // register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nome: "",
      email:"",
      celular:"",
      aceite:""
    }
  });


  const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

  const handleEmailValidation = email => {
    // console.log("ValidateEmail was called with", email);

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      // console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };
  
  const onSubmit = async data => {

    const values = {
      nome: "",
      email:data.email.replace(/\s/g, ""),
      celular: "",
      fonte: "HeroLand001",
      lancamento: "0",
      interesse: "news letter",
    }

    const res = await postLead(values);
    
    if (String(res) === '201') {


      // ReactGA.event({
      //   category: "Lead",
      //   action: "LeadSubmitProgram",
      //   label: "Hero", // optional
      //   value: 1, // optional, must be a number
      //   // nonInteraction: true, // optional, true/false
      //   // transport: "xhr", // optional, beacon/xhr/image
      // });

      alert('Muito obrigado! Entraremos em contato em breve.');

      window.location.href='/'; 
    
    } else {
      alert('Erro ao registrar seus dados!');
    }

  };

  const onErrors = errors => {
    console.error(errors)
  };





  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" >
        <div
          className={innerClasses} 
        >



        <Container >
          <form onSubmit={handleSubmit(onSubmit,  onErrors)}>
          <Grid container spacing={0}>
          <Grid item xs={12} md={7} >
          <div className="cta-slogan">
            <h4 className="m-0" >
              Para mais informações cadastre seu email
              </h4>
          </div>
          </Grid>

            <Grid item xs={9} md={3.5} >
                <Controller
                  id={"email"}
                  name={"email"}
                  control={control}
                  rules={{ required: true, validate: handleEmailValidation}}
                  render={({ field: { onChange, onBlur,value } }) => (
                    <TextField 
                      fullWidth 
                      onBlur={onBlur}
                      onChange={onChange} 
                      value={value} 
                      label={"Email"} 
                    />
                  )}
                />
                {errors.email && errors.email.type === "required" && 
                  <span style={{fontSize: "0.8rem", color:'white', fontWeight: 500,  }}>
                    Informe seu email. 
                  </span>
                }
                {errors.email && 
                  <span style={{fontSize: "0.8rem", color:'white', fontWeight: 500,  }}>
                    Não válido.
                  </span>
                }
              </Grid> 
              <Grid item xs={3} md={1.5}  >
                <IconButton type="submit" size="large" aria-label="enviar"  >
                  <SendIcon sx={{color:"white"}}/>
                </IconButton >
              </Grid>
              <Grid item xs={0} md={12}>

              </Grid>  


              </Grid>  

            </form>
</Container>

          </div>
        </div>

    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;