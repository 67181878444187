import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import ForWho from '../components/sections/ForWho';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';


//GOOGLE ANALYTICS
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";


ReactGA.initialize("G-8BNDD3LX7W");

ReactGA._gtag('event', 'page_view', {
  page_title: 'Home',
  page_location: 'https://www.artificialmentor.com.br',  // The full URL is required.
  send_to: 'G-8BNDD3LX7W'
});


const Home = () => {

  return (
    <>
  <HelmetProvider>
    <Helmet>
    <title> Artificial Mentor Consultoria</title> */}
        <meta
        name="description"
        content="O passo a passo para sua empresa atingir a alta lucratividade."
        />
        <meta
        name="keywords"
        content="Consultoria de negócios especializada no aumento da lucratividade de pequenas e médias empresas."
        />
        <link
        rel="canonical" href="https://www.artificialmentor.com.br/"
        />
    </Helmet>
  </HelmetProvider>


      <Hero className="illustration-section-01" />
      <ForWho />
      <FeaturesTiles topDivider />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split />

    </>
  );
}

export default Home;