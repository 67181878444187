import axios from 'axios';




export const postLead = async (values) => {

    console.log(process.env.REACT_APP_API_URL + '/api/v1/leads/create')
  
    try{
      const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/api/v1/lead/create',

      data: {
        nome: values.nome,
        email: values.email,
        celular: values.celular,
        fonte: values.fonte,
        lancamento: values.lancamento,
        interesse: values.interesse,
      }
    });

    console.log(response)
 
    if (response.status === 201) {
  
      return response.status
    }
    
    } catch (err) {
      alert('if else')
      return err.message.replace(/^\D+/g, '');
      }
    }