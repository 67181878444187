import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import { Container, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const ForWho = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Para quem é a nossa consultoria?',
    paragraph: 'Para empresários de pequenas e médias empresas desejam:'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >

        <SectionHeader data={sectionHeader} className="center-content" />



            <Container alignItems='center' sx={{width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} >
                  <Grid container spacing={2}>

                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                        Eliminar as principais deficiências que levam embora o lucro do seu negócio.
                      </h5>
                    </Grid>



                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                        Controlar os indicadores chaves para aumentar o lucro da sua empresa.
                      </h5>
                    </Grid>


                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                      Acertar na precificação e criar um portfólio mais lucrativo.
                      </h5>
                    </Grid>

                  </Grid>
                </Grid>




                <Grid item xs={12} md={6} >
                  <Grid container spacing={2}>

                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                        Traçar um passo a passo rumo a alta lucratividade e forte geração de caixa.
                      </h5>
                    </Grid>



                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                        Alavancar o valor de mercado da sua empresa de forma consistente.
                      </h5>
                    </Grid>


                    <Grid item xs={0} md={2}>
                    </Grid>   
                    <Grid item xs={2} md={2} align='right' >
                      <CheckCircleIcon color='secondary' style={{ width:30, height: 30, color:'#e65100' }}/>
                    </Grid>                 
                    <Grid item xs={9} md={8} >
                      <h5 className="mt-0 mb-12" align="left">
                        Ser mentorado durante o processo de transformação do seu negócio.
                      </h5>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Container>


            <br/>
            <br/>
            <br/>
            <br/>

    </section>
  );
}

ForWho.propTypes = propTypes;
ForWho.defaultProps = defaultProps;

export default ForWho;