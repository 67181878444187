import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Conheça nossa metodologia',
    paragraph: 'Sabemos que aumentar a lucratividade de um negócio não é uma tarefa simples, por isso criamos um método eficiente que combina mentorias, um avançado ambiente de trabalho e acompanhamento da evolução do seu negócio.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  UM ESPECIALISTA AO SEU LADO
                  </div>
                <h3 className="mt-0 mb-12">
                  Mentorias individuais
                  </h3>
                <p className="m-0">
                Reuniões virtuais para identificar as deficiências que atrapalham a lucratividade do seu negócio, indicar o que deve ser feito para eliminar essas deficiências e esclarecer suas dúvidas. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/como01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  MÉTODO E ORGANIZAÇÃO
                  </div>
                <h3 className="mt-0 mb-12">
                  Plataforma avançada
                  </h3>
                <p className="m-0">
                Uma verdadeira central de controle, onde vamos analisar os números do seu negócio, gerenciar tarefas que nosso especialista te passar, acompanhar suas metas e registrar suas conquistas rumo a alta lucratividade.                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/como02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  GALERIA DE CONQUISTAS
                  </div>
                <h3 className="mt-0 mb-12">
                  Comemore cada conquista 
                  </h3>
                <p className="m-0">
                A transformação definitiva da sua empresa ocorre através de uma sequência de pequenas conquistas, comemore cada passo bem sucedido e colecione conquistas em sua galeria exclusiva.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/como03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  SATISFAÇÃO EM PRIMEIRO LUGAR
                </div>
                <h3 className="mt-0 mb-12">
                  Garantia incondicional
                  </h3>
                <p className="m-0">
                Após contratar nossa consultoria, você poderá usufruir por 30 dias, se nesse período perceber que não faz sentido continuar, basta enviar um e-mail para suporte@artificialmentor.com.br e pedir o reembolso integral sem nenhuma justificativa.                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/como04.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>




          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;